.users-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    .btn {
        width: 100%;
        max-width: 200px;
        margin: 0 10px;
    }
}