.group-page {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        height: 20px;
        margin-top: 20px;
    }

    .id {
        font-size: 14px;
        color: #868686;
        font-weight: 400;
    }

    .details {
        h3 {
            display: flex;
            align-items: center;
            font-size: 16px;
            gap: 20px;
            font-weight: 400;

            span {
                font-size: 14px;
                color: #868686;
                font-weight: 400;
            }
        }
    }

    .member-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}