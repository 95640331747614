.school {
    margin-top: 20px;

    .container {
        display: flex;
        align-items: start;
        gap: 30px;

        @media (max-width: 1230px) {
            flex-direction: column;
        }

        .left {
            width: 40%;
            border-right: 1px solid #dfdfdf;

            @media (max-width: 1230px) {
                width: 100%;
                border-right: none;
            }

            .school-image {
                width: 400px;
                display: block;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.1);
                        transition: all 0.3s ease-in-out;
                    }
                }


                @media (max-width: 768px) {
                    width: 300px;
                    margin: auto;
                }
            }

            .school-info {
                .school-info-item {
                    display: flex;
                    align-items: center;

                    h2 {
                        margin: 0;
                        font-size: 1rem;
                        font-weight: 600;
                        color: #333;
                        width: 230px;

                        @media (max-width: 1280px) {
                            width: 150px;
                        }

                        @media (max-width: 1230px) {
                            width: 200px;
                        }

                        @media (max-width: 768px) {
                            width: 120px;
                        }
                    }
                }
            }

            .school-actions {
                display: flex;
                align-items: center;
                margin-top: 30px;
                gap: 10px;
            }
        }

        .right {
            width: 60%;

            @media (max-width: 1230px) {
                width: 100%;
            }
        }
    }
}