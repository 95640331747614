$menu-slider-width: 220px;
$dashboard-header-height: 64px;

$menu-slider-width-ss: 200px;
$menu-slider-width-xs: 0px;

$dashboard-header-height-ss: 54px;
$dashboard-header-height-xs: 54px;

// colors 
$primary-color: #673bf9;
$bit-light-primary-color: #7c5df9;
$light-primary-color: #ddcfff;
$very-light-primary-color: #f3f0ff;

$gray: #4f4f4f;
$light-gray: #f5f5f5;
$very-light-gray: #fafafa;