.user-progress {

    .progress-courses {
        .ant-collapse {
            border: none;
            background-color: transparent;

            .ant-collapse-item {
                background: #fff;
                border: none;
                margin: 0 0 20px 0;
                border-radius: 10px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

                .ant-collapse-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .progress-course__body {
                    ul {
                        margin: 0;

                        li {
                            list-style: disc;

                            div {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .is-completed {
                                    .completed {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background: #00b21e;
                                        border-radius: 50px;

                                        .MuiSvgIcon-root {
                                            padding: 3px;
                                            color: #fff;
                                        }
                                    }

                                    .not {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background: #dbdbdb;
                                        border-radius: 50px;

                                        .MuiSvgIcon-root {
                                            padding: 3px;
                                            color: #bcbcbc;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .progress-course__label {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;


                    .title {
                        font-size: 1.2rem;
                        font-weight: 500;
                    }

                    span {
                        width: 40%;
                    }

                    .progress {
                        width: 60%;

                        .ant-progress {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .ant-progress-text {
                                width: 50px;
                                transform: translateY(8px);
                            }
                        }
                    }

                    @media (max-width: 600px) {
                        flex-direction: column;
                        gap: 5px;

                        span {
                            width: 100%;
                        }

                        .progress {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}