.Slider {
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 auto 30px auto;
  }
  .Slider .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Slider h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5em;
  }
  .Slider .bar {
    width: 180px;
    height: 0.3rem;
    background-color: #cd68ff;
    margin-bottom: 1rem;
    border-radius: 50px;
    margin-top: -0.5em;
    padding-top: 0;
  }
  .Slider .card {
    height: 25rem;
    width: 18rem;
    background-color: rgb(241, 245, 255);
    border-radius: 10px;
    padding: 1rem;
    justify-content: Left;
    margin: auto;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .Slider .card:hover{
    background-color: rgb(241, 245, 255);
    box-shadow: 0 0 11px rgba(33,33,33,.2);
    transform: scale(1.05);
  }
  .Slider .card img{
    width: 5em;
    height: 5em;
    border-radius: 100px;
  }

  .Slider .card .card-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 240px
  }

  .Slider h4 {
    color: #000;
    margin: 10px 0;
    font-size: 1rem;
  }
  
  .Slider p {
    color: #6d6d6d;
    font-size: 0.9rem;
  }

  .Slider h6 {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
    color: #6d6d6d;
  }
  
  .Slider .carousel-container {
    height: 30em;
  }
  
  .Slider .custom-dot-list-style button {
    border: none;
  }
