.assessment {
    display: flex;
    gap: 20px;

    .left {
        flex: 1;
        border-right: 1px solid #e0e0e0;
        min-height: 83vh;

        .hedaer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .body {
            h2 {
                font-size: 1rem;
                font-weight: 500;
                margin-top: 20px;
                color: #484848;

                span {
                    color: #000000;
                }
            }

            .quill {
                padding: 0 !important;
                margin: 0;

                .ql-editor {
                    padding: 0 !important;
                    margin: 0;
                }
            }
        }
    }

    .right {
        flex: 1.5;
    }

    @media (max-width: 900px) {
        flex-direction: column;

        .left {
            border-right: none;
            min-height: 100%;
            border-bottom: 1px solid #e0e0e0;
        }
    }
}