.assessments {
    .assessments-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .assessments-header-title {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;

            .select {
                width: 250px;
                margin-left: 5px;
            }

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;

                h1 {
                    margin: 0;
                    padding: 0;
                }

                .select {
                    width: 200px;
                    margin-left: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
}