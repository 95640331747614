@import '../../../../../theme/variables.scss';

.course {
    .course-header {
        margin-right: 50px;
        display: flex;
        justify-content: end;
        width: 100%;
    }

    .course-continer {
        min-height: 80vh;
        width: 100%;

        .no-data {
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                margin-top: 20px;

                h1 {
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: #6f6c6c;
                }

                img {
                    width: 500px;
                }

                @media (max-width: 768px) {
                    img {
                        width: 300px;
                    }

                    h1 {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .no-data-mini {
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                margin-top: 20px;

                h1 {
                    font-size: 1rem;
                    font-weight: 400;
                    color: #6f6c6c;
                }

                img {
                    width: 300px;
                }

                @media (max-width: 768px) {
                    img {
                        width: 200px;
                    }
                }
            }
        }

        @media (max-width: 1000px) {
            .title-mobile {
                margin: 0;
                padding: 0;
            }
        }

        @media (max-width: 768px) {
            .title-mobile {
                font-size: 1.5rem;
                margin: 0;
                padding: 0;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }

        .body {
            display: flex;
            gap: 40px;
            width: 100%;
            height: 100%;

            @media (max-width: 1280px) {
                gap: 10px;
            }

            @media (max-width: 1000px) {
                flex-direction: column;
            }

            .no-content {
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .course-left {
                width: 70%;
                height: 100%;
                order: 1;

                @media (max-width: 1000px) {
                    width: 100%;
                    order: 2;
                }

                @media (max-width: 768px) {
                    .content-title {
                        font-size: 1.4rem;
                        font-weight: 500;
                    }
                }

                iframe {
                    width: 100%;
                    height: 600px;
                    border-radius: 20px;
                    box-shadow: 1px 1px 10px 1px #0000001a;
                }

                h2 {
                    font-size: 1.3rem;
                    font-weight: 600;
                    margin: 1rem 0;
                }

                p {
                    font-weight: 400;
                    margin: 1rem 0;
                    color: $gray;
                    line-height: 1.5;
                }

                @media (max-width: 1280px) {
                    iframe {
                        height: 400px;
                        border-radius: 10px;
                    }

                    h1 {
                        font-size: 1.5rem;
                    }
                }

                .drive-floder {
                    margin-bottom: 20px;

                    iframe {
                        width: 100%;
                        height: 300px;
                        border: none;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px 1px #0000001a;
                    }
                }

                .course-footer {
                    .completed {
                        background-color: #00C618;
                        color: #fff;
                    }
                }
            }

            .course-right {
                width: 30%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
                order: 2;

                @media (max-width: 1000px) {
                    width: 100%;
                    order: 1;
                    padding: 0 0 15px 0;
                    border-bottom: 1px solid rgb(209, 209, 209);
                }

                .progress {
                    padding: 0em 2em 1em 2em;
                    background: #fff;
                    border-radius: 20px;
                    box-shadow: 1px 1px 10px 1px #0000001a;

                    h2 {
                        display: flex;
                        align-items: center;
                        font-size: 1.3rem;
                        margin: 10px 0 0 0;

                        p {
                            font-size: 1rem;
                        }

                        span {
                            margin-left: 10px;
                            color: $primary-color;
                        }
                    }
                }

                .join_meeting {
                    margin-top: 20px;
                    padding: 0em 2em 1em 2em;
                    background: #fff;
                    border-radius: 20px;
                    box-shadow: 1px 1px 10px 1px #0000001a;

                    .header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                    }

                    h3 {
                        margin: 0;
                        padding: 0;
                        font-weight: 500;
                        color: $primary-color;

                        span {
                            font-weight: 600;
                            color: #000;
                        }
                    }

                    p {
                        padding: 0;
                        color: $gray;

                        span {
                            font-weight: 600;
                        }
                    }

                    .meeting-btn {
                        width: 80%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 20px auto 0 auto;
                        gap: 20px;

                        .ant-btn {
                            width: 120px;
                        }
                    }
                }

                .course-content {
                    width: 100%;

                    h2 {
                        display: flex;
                        align-items: center;
                        font-size: 1.3rem;
                        justify-content: space-between;
                        margin-top: 30px;

                        span {
                            font-size: 1rem;
                            color: $gray;
                            font-weight: 500;
                        }
                    }

                    .ant-list {
                        border: none;

                        .ant-list-item {
                            border: none;
                            background: #fff;
                            margin: 10px 0;
                            display: flex;
                            align-items: center;
                            border-radius: 10px;
                            box-shadow: 1px 1px 10px 1px #0000001a;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                background: $light-gray;
                                transform: scale(1.02);
                                transition: all 0.3s ease-in-out;
                            }

                            .btn {
                                width: 40px;
                                height: 40px;
                                border-radius: 100px;
                                background: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                            }

                            .course-content-item {
                                width: 75%;
                                display: flex;
                                justify-content: start;
                                align-items: start;
                                padding: 5px 0;
                                flex-direction: column;
                                text-align: start;
                                margin: 0;
                                gap: 5px;

                                p {
                                    font-size: 0.9rem;
                                    font-weight: 500;
                                    width: 100%;
                                    padding: 0;
                                    margin: 0;
                                }

                                span {
                                    font-size: 0.8rem;
                                    font-weight: 400;
                                    color: $gray;
                                }
                            }

                            .status {
                                width: 25px;
                                height: 25px;
                                display: flex;
                                justify-content: center;

                                .icon {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 100%;
                                    background-color: #00C618;
                                }

                                .not {
                                    background-color: #c8c8c8;
                                }
                            }
                        }

                        .active {
                            background: $primary-color;

                            &:hover {
                                background: $bit-light-primary-color;
                            }

                            .course-content-item {

                                p {
                                    color: #fff;
                                }

                                span {
                                    color: #fff;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}