.signin-page {
    display: flex;
    justify-content: space-between;

    section {
        width: 100%;
    }

    .bg-img {
        position: fixed;
        width: 40%;
        height: 100vh;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: -1;
            opacity: 0.7;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                width: 100%;

                img {
                    width: 120px;
                    margin: 20px;
                }
            }

            h1 {
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 10px;
            }

            span {
                font-size: 2.5rem;
                font-weight: 500;
                margin-bottom: 30px;
            }

            p {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 20px;
                width: 60%;
                text-align: center;
            }

            .by {
                font-style: italic;
            }

            h2 {
                font-size: 1.5rem;
                font-weight: 500;
                margin-bottom: 20px;
            }

            .learn-img {
                width: 400px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            @media (max-width: 1280px) {
                .logo {
                    img {
                        width: 150px;
                        margin: 10px;
                    }
                }

                h1 {
                    font-size: 1.5rem;
                }

                span {
                    font-size: 2rem;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 1rem;
                    width: 80%;
                }

                h2 {
                    font-size: 1.2rem;
                }

                .learn-img {
                    width: 320px;
                }

            }
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .form {
        width: 60%;
        margin-left: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;

        .form-container {
            width: 50%;

            .logo-mobile {
                display: none;
            }

            .title {
                font-size: 1.5rem;
                font-weight: 500;
                margin-bottom: 50px;
                text-align: center;
            }

            .ant-form-item-control-input-content {
                display: flex !important;
                justify-content: start;
                align-items: center;

                .form-button {
                    width: 150px;
                    height: 50px;
                    border-radius: 50px;
                }
            }

            .sign-up {
                text-align: center;
                margin-top: 60px;

                h1 {
                    font-size: 1rem;
                    font-weight: 400;
                    margin-bottom: 20px;

                    a {
                        margin-left: 0px;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            margin-left: 0;

            .form-container {
                width: 80%;

                .logo-mobile {
                    display: block;
                    text-align: center;

                    img {
                        width: 150px;
                        margin: 20px;
                    }
                }

                .title {
                    font-size: 1.2rem;
                    margin-bottom: 30px;
                }

                .ant-form-item-control-input-content {
                    flex-direction: column;

                    .form-button {
                        width: 150px;
                        height: 40px;
                    }

                    .sign-up {
                        margin-top: 10px;

                        h1 {
                            font-size: 0.8rem;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}