.submission-review-drawer {
    .submission-details {
        margin: 0 0 20px 0;

        h2 {
            margin: 0 0 15px 0;
            font-weight: 600;
            font-size: 0.9rem;
            color: #676767;
            display: flex;

            .id {
                font-weight: 500;
                color: #676767;
                width: 120px;
            }

            span {
                font-weight: 400;
                color: #000;
            }
        }
    }
}